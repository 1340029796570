@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

.cardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  font-family: 'Roboto Condensed', sans-serif;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 750px;
  height: 60vh;
  background-color: seashell;
  overflow: scroll;
  border-radius: 10px;
  border: 1px solid rgb(207, 207, 207);
  box-shadow: 0px 1px 1px 1px rgb(184, 184, 184);
  transition: all 0.5s ease-in-out;
  position: relative;
}

.cardHeadline {
  text-align: center;
}

.cardDetails {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.card:hover {
  transform: translateY(-5px);
}

.slogan {
  margin: 1rem;
  color: rgb(82, 82, 82);
}

p,
a {
  margin-left: 1rem;
}

.address {
  display: flex;
}

.city {
  margin-left: 2rem;
}

.icon {
  color: cadetblue;
}

.locationIcon {
  padding-right: 0.2rem;
  transform: translateY(12px);
}

.phone {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 1200px) {
  .card {
    width: 65vw;
  }
}

@media screen and (max-width: 700px) {
  .card {
    width: 80vw;
  }
}
