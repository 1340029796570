@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  overflow-y: hidden;
}

html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@supports (padding: max(0px)) {
  body,
  header,
  footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
  }
}

.App {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/images/background.png);
  height: 100vh;
}

.Card_cardContainer__2n9UX {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  font-family: 'Roboto Condensed', sans-serif;
}

.Card_card__1N5V- {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 750px;
  height: 60vh;
  background-color: seashell;
  overflow: scroll;
  border-radius: 10px;
  border: 1px solid rgb(207, 207, 207);
  box-shadow: 0px 1px 1px 1px rgb(184, 184, 184);
  transition: all 0.5s ease-in-out;
  position: relative;
}

.Card_cardHeadline__1Pxqj {
  text-align: center;
}

.Card_cardDetails__2SMDg {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.Card_card__1N5V-:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.Card_slogan__3_m75 {
  margin: 1rem;
  color: rgb(82, 82, 82);
}

p,
a {
  margin-left: 1rem;
}

.Card_address__2y2S2 {
  display: flex;
}

.Card_city__1doW6 {
  margin-left: 2rem;
}

.Card_icon__28BKj {
  color: cadetblue;
}

.Card_locationIcon__9MoXV {
  padding-right: 0.2rem;
  -webkit-transform: translateY(12px);
          transform: translateY(12px);
}

.Card_phone___9uf2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 1200px) {
  .Card_card__1N5V- {
    width: 65vw;
  }
}

@media screen and (max-width: 700px) {
  .Card_card__1N5V- {
    width: 80vw;
  }
}

.Header_header__1R8F- {
  display: flex;
  justify-content: center;
  background-color: cadetblue;
  font-family: 'Lobster', cursive;
  color: white;
  padding: 1rem;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -webkit-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

.Header_headingContainer__3Mdd- {
  display: flex;
  cursor: pointer;
}

.Header_logo__oMe1R {
  width: 50px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
}

