@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.header {
  display: flex;
  justify-content: center;
  background-color: cadetblue;
  font-family: 'Lobster', cursive;
  color: white;
  padding: 1rem;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.headingContainer {
  display: flex;
  cursor: pointer;
}

.logo {
  width: 50px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
}
